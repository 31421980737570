
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lto-delivery-admin-primary: mat-palette($mat-indigo);
$lto-delivery-admin-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$lto-delivery-admin-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$lto-delivery-admin-theme: mat-light-theme((
  color: (
    primary: $lto-delivery-admin-primary,
    accent: $lto-delivery-admin-accent,
    warn: $lto-delivery-admin-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($lto-delivery-admin-theme);

@import url('./assets/fonts/app_fonts.css');

html {
  height: 100%;
}
body {
  height: 100%;
}

body {
  --text-color: #2D2E37;
  --background-color: #FFFFFF;
  --btn-txt-color: #2D2E37;
  --btn-bg-color: #FFFFFF;

  --primary-color: #2D2E37;
  --primary-bg-color: #FFFFFF;

  --secondary-color: #FFFFFF;
  --secondary-bg-color: #2D2E37;

  --icon-color: #FFFFFF;
  --icon-bg-color: #000000;

  font-family: 'Jakarta Sans' !important;

}

body.dark-theme {
  --text-color: #F7CA58;
  --background-color: #FFFFFF;
  --btn-txt-color: #2D2E37;
  --btn-bg-color: #FFFFFF;

  --primary-color: #2D2E37;
  --primary-bg-color: #FFFFFF;

  --secondary-color: #FFFFFF;
  --secondary-bg-color: #2D2E37;

  --icon-color: #000000;
  --icon-bg-color: #FFFFFF;
}

/* You can add global styles to this file, and also import other style files */

/* Media Query */
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #sidebar-overlay {
    z-index: 0 !important;
  }
}

/* Modals */
.connect-wallet-modal {
  .modal-content {
    background: #2D2E37;
    border-radius: 8px;
  }
}

.swap-processing-modal {
  max-width: 356px !important;
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
  }
}

.select-token-modal {
  max-width: 90% !important;
  // max-width: 300px !important;
  .modal-content {
    padding: 10px;
    border-radius: 8px;
    border: none;
  }
}

.delete-sms-status-modal {
  max-width: 300px !important;
  // max-width: 300px !important;
  .modal-content {
    padding: 10px;
    border-radius: 8px;
    border: none;
  }
}

.spinner-modal {
  // max-width: 300px !important;
  .modal-content {
    border: none !important;
  }
}

.success-modal {
  max-width: 300px !important;
  .modal-content {
    padding: 10px;
    border-radius: 8px;
    border: none;
  }
}

.assign-deliveries-modal {
  // max-width: 300px !important;
  max-width: 90% !important;
  .modal-content {
    padding: 10px;
    border-radius: 8px;
    border: none;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.re-assign-deliveries-modal {
  width: 90% !important;
  max-width: 400px;
  .modal-content {
    padding: 10px;
    border-radius: 8px;
    border: none;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.view-delivery-modal {
  // max-width: 300px !important;
  max-width: 90% !important;
  .modal-content {
    padding: 10px;
    border-radius: 8px;
    border: none;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.capture-image-modal {
  width: 90% !important;
  max-width: 530px !important;
  .modal-content {
    border-radius: 8px;
    padding-top: 20px;
    border: none;
    // height: 500px !important;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 1px 1px 11px 2px black;
  }
}


.loading-spinner {
  .modal-content {
    border-radius: 8px;
    border: none !important;
  }
}

.select-duration-modal {
  max-width: 90% !important;
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
    width: 60%;
  }
}

.confirm-transaction-modal {
  display: flex;
  justify-content: center;
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
    width: 100%;
  }
}

.swap-success-modal {
  max-width: 356px !important;
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
  }
}

.error-modal{
  .modal-content {
    width:100%;
  }
}


.disclaimer-modal {
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
  }
}

.swap-failed-modal {
  max-width: 356px !important;
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
  }
}

.swap-confirmation-modal {
  .modal-content {
    border-radius: 8px;
    border: none;
  }
}

.swap-confirmation-dark-modal {
  .modal-content {
    border-radius: 8px;
    border: none;
    background: rgba(45, 46, 55, 1);
  }
}

.insufficient-balance-modal{
  .modal-content{
    border-radius: 16px;
    background: #1D1E27;
    border: none;
  }
}

.staking-confirmation-modal{
  .modal-content{
    border-radius: 16px;
    background: #1D1E27;
    border: none;
  }
}

.flexible-confirmation-modal{
  .modal-content{
    border-radius: 16px;
    background: #1D1E27;
    border: none;
  }
}

.end-pool-modal{
  .modal-content{
    border-radius: 16px;
    background: #1D1E27;
    border: none;
  }
}

.success-trans-modal {
  max-width: 356px !important;
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
  }
}

.failed-trans-modal {
  max-width: 356px !important;
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
  }
}

.add-funds-modal {
  max-width: 500px !important;
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
  }
}

.verify-user-modal {
  max-width: 356px !important;
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
  }
}
.profile-option-modal {
  
  .modal-content {
    background: #ffffff;
    border-radius: 8px;
    width: 300px;
  }
}

.vest-claim-modal {
  max-width: 500px !important;
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
  }
}

.add-vest-modal{
  max-width: 600px !important;
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
  }
}

.loading-trans-modal {
  max-width: 356px !important;
  .modal-content {
    background: none;
    border-radius: 8px;
    border: none;
  }
}

.scanner-modal {
  max-width: 70% !important;
  .modal-content {
    border-radius: 8px;
    padding: 10px;
  }
}

.capture-image-modal{
  .modal-content {
    border-radius: 8px;
    padding: 10px;
  }
}

modal-container{
  display: flex !important;
  justify-content: center !important;
}

// .modal-dialog-centered {
//   display:-webkit-box;
//   display:-ms-flexbox;
//   display:flex;
//   -webkit-box-align:center;
//   -ms-flex-align:center;
//   align-items:center;
//   min-height:calc(100% - (.5rem * 2));
// }

@media screen and (min-width: 720px) {
  .view-delivery-modal {
    width: 600px !important;
  }
}


@media screen and (max-width: 720px) {
  .scanner-modal {
    min-width: 95% !important;
  }
}

.main-container {
  padding: 16px;
}
.bs-datepicker-body table td.week span 
{
  color: #2D2E37 !important; 
}

.bs-datepicker,
.bs-datepicker-body,
.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker-head, .bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover ,
.bs-datepicker-container,
.bs-datepicker-body table td
{
  color: white !important;
  background-color: #1d1e27 !important; 
  box-shadow: none !important;
  border: none !important;
}
.bs-datepicker-body table td span.is-other-month{
  color: rgb(133, 133, 133) !important;
}
/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";
html, body { height: 100%; }
body { margin: 0; font-family: "Jakarta Sans" !important; }

.pointer{
  cursor: pointer;
}

h1, h2, h3, h4{
  font-family: "Jakarta Sans" !important;
}

button.btn-primary{
  background: #0BC7F8 !important;
  outline: none !important;
  border: none !important;
}

button.btn-link {
  font-weight: 400;
  color: #007bff !important;
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.jakarta{
  font-family: "Jakarta Sans" !important;
}

.capitalize{
  text-transform: capitalize;
}

  /* Font size */
  .t-fs70-36{
    font-size: 70.36px;
  }
  .t-fs60{
    font-size: 60px;
  }
  .t-fs50{
    font-size: 50px;
  }
  .t-fs48{
    font-size: 48px;
  }
  .t-fs45-28{
    font-size: 45.28px;
  }
  .t-fs43-4{
    font-size: 43.4px;
  }
  .t-fs44{
    font-size: 40px;
  }
  .t-fs40{
    font-size: 40px;
  }
  .t-fs27-68{
    font-size: 27.68px;
  }
  .t-fs38-43{
    font-size: 38.43px;
  }
  .t-fs36{
    font-size: 36px;
  }
  .t-fs34-67{
    font-size: 34.67px;
  }
  .t-fs32{
    font-size: 32px;
  }
  .t-fs30{
    font-size: 30px;
  }
  .t-fs34-44{
    font-size: 34.44px;
  }
  .t-fs34-29{
    font-size: 34.29px;
  }
  .t-fs26{
    font-size: 26px;
  }
  .t-fs24{
    font-size: 24px;
  }
  .t-fs23{
    font-size: 23px;
  }
  .t-fs22{
    font-size: 22px;
  }
  .t-fs22-36{
    font-size: 22.36px;
  }
  .t-fs20-83{
    font-size: 20.83px;
  }
  .t-fs21{
    font-size: 21px;
  }
  .t-fs20{
    font-size: 20px;
  }
  .t-fs19-82{
    font-size: 19.82px;
  }
  .t-fs18{
    font-size: 18px;
  }
  .t-fs17-12{
    font-size: 17.12px;
  }
  .t-fs16-76{
    font-size: 16.76px;
  }
  .t-fs16{
    font-size: 16px !important;
  }
  .t-fs15{
    font-size: 15px;
  }
  .t-fs14-98{
    font-size: 14.98px;
  }
  .t-fs14-91{
    font-size: 14.91;
  }
  .t-fs14-93{
    font-size: 14.93px;
  }
  .t-fs14-69{
    font-size: 14.69px;
  }
  .t-fs14-09{
    font-size: 14.09px;
  }
  .t-fs14{
    font-size: 14px;
  }
  .t-fs13-89{
    font-size: 13.89px;
  }
  .t-fs13{
    font-size: 13px;
  }
  .t-fs12-79{
    font-size: 12.79px;
  }
  .t-fs12-64{
    font-size: 12.64px;
  }
  .t-fs12-62{
    font-size: 12.62px;
  }
  .t-fs12-43{
    font-size: 12.43px;
  }
  .t-fs12-41{
    font-size: 12.41px;
  }
  .t-fs12-26{
    font-size: 12.26px;
  }
  .t-fs12-15{
    font-size: 12.15px;
  }
  .t-fs12-06{
    font-size: 12.06px;
  }
  .t-fs12{
    font-size: 12px !important;
  }
  .t-fs10-91{
    font-size: 10.91px;
  }
  .t-fs10-69{
    font-size: 10.69px;
  }
  .t-fs10-51{
    font-size: 10.51px;
  }
  .t-fs10{
    font-size: 10px !important;
  }
  .t-fs7{
    font-size: 7px !important;
  }
  .t-fs11-77{
    font-size: 11.77px;
  }
  .t-fs11-61{
    font-size: 11.61px;
  }
  .t-fs11-38{
    font-size: 11.38px;
  }
  .t-fs11-28{
    font-size: 11.28px;
  }
  .t-fs11-8{
    font-size: 11.8px;
  }
  .t-fs11-07{
    font-size: 11.07px;
  }
  .t-fs11-4{
    font-size: 11.4px;
  }
  .t-fs11{
    font-size: 11px;
  }
  .t-fs10-87{
    font-size: 10.87px;
  }
  .t-fs10-83{
    font-size: 10.83px;
  }
  .t-fs10-77{
    font-size: 10.77px;
  }
  .t-fs10-07{
    font-size: 10.07px;
  }
  .t-fs10{
    font-size: 10px;
  }
  .t-fs9-77{
    font-size: 9.77px;
  }
  .t-fs9-55{
    font-size: 9.55px;
  }
  .t-fs9-48{
    font-size: 9.48px;
  }
  .t-fs9-29{
    font-size: 9.29px;
  }
  .t-fs9-27{
    font-size: 9.27px;
  }
  .t-fs9-3{
    font-size: 9.3px;
  }
  .t-fs9{
    font-size: 9px;
  }
  .t-fs9-19{
    font-size: 9.19px;
  }
  .t-fs9-13{
    font-size: 9.13px;
  }
  .t-fs8-77{
    font-size: 8.77px;
  }
  .t-fs8-38{
    font-size: 8.38px;
  }
  .t-fs8-29{
    font-size: 8.29px;
  }
  .t-fs8{
    font-size: 8px;
  }
  .t-fs7-67{
    font-size: 7.67px;
  }
  .t-fs7-82{
    font-size: 7.82px;
  }
  .t-fs7{
    font-size: 7px;
  }
  
   /* Line Height */
  .t-lh89-83{
    line-height: 89.83px;
  }
  .t-lh81-71{
    line-height: 81.71px;
  }
   .t-lh68-09{
    line-height: 68.09px;
  }
  .t-lh65-37{
    line-height: 65.37px;
  }
  .t-lh61-67{
    line-height: 61.67px;
  }
  .t-lh59-1{
    line-height: 59.1px;
  }
  .t-lh56-18{
    line-height: 56.18px;
  }
  .t-lh52{
    line-height: 52px;
  }
  .t-lh52-33{
    line-height: 52.33px;
  }
  .t-lh54-47{
    line-height: 54.47px;
  }
  .t-lh47{
    line-height: 47px;
  }
  .t-lh40{
    line-height: 40px;
  }
  .t-lh37-7{
    line-height: 37.7px;
  }
  .t-lh35-41{
    line-height: 35.41px;
  }
  .t-lh33{
    line-height: 33px;
  }
  .t-lh32-68{
    line-height: 32.68px;
  }
  .t-lh30-46{
    line-height: 30.46px;
  }
  .t-lh31-32{
    line-height: 31.32px;
  }
  .t-lh30-72{
    line-height: 30.72px;
  }
  .t-lh29-96{
    line-height: 29.96px;
  }
  .t-lh28-96{
    line-height: 28.96px;
  }
  .t-lh28-6{
    line-height: 28.6px;
  }
  .t-lh28-37{
    line-height: 28.37px;
  }
  .t-lh27-24{
    line-height: 27.24px;
  }
  .t-lh25-2{
    line-height: 25.2px;
  }
  .t-lh24-51{
    line-height: 24.51px;
  }
  .t-lh23-32{
    line-height: 23.32px;
  }
  .t-lh24{
    line-height: 24px;
  }
  .t-lh22-83{
    line-height: 22.83px;
  }
  .t-lh26-99{
    line-height: 26.99px;
  }
  .t-lh21-79{
    line-height: 21.79px;
  }
  .t-lh21-72{
    line-height: 21.72px;
  }
  .t-lh20-43{
    line-height: 20.43px;
  }
  .t-lh20-3{
    line-height: 20.3px;
  }
  .t-lh20-34{
    line-height: 20.34px;
  }
  .t-lh20{
    line-height: 20.34px;
  }
  .t-lh19-31{
    line-height: 19.31px;
  }
  .t-lh19-19{
    line-height: 19.19px;
  }
  .t-lh19-13{
    line-height: 19.13px;
  }
  .t-lh19-07{
    line-height: 19.07px;
  }
  .t-lh18-95{
    line-height: 18.95px;
  }
  .t-lh18-91{
    line-height: 18.91px;
  }
  .t-lh18-05{
    line-height: 18.05px;
  }
  .t-lh17-42{
    line-height: 17.42px;
  }
  .t-lh17-7{
    line-height: 17.7px;
  }
  .t-lh17-08{
    line-height: 17.08px;
  }
  .t-lh16-55{
    line-height: 16.55px;
  }
  .t-lh16-43{
    line-height: 16.43px;
  }
  .t-lh16-36{
    line-height: 16.36px;
  }
  .t-lh16-34{
    line-height: 16.34px;
  }
  .t-lh16-9{
    line-height: 16.9px;
  }
  .t-lh16-07{
    line-height: 16.07px;
  }
  .t-lh16-03{
    line-height: 16.03px;
  }
  .t-lh15-82{
    line-height: 15.82px;
  }
  .t-lh15-76{
    line-height: 15.76px;
  }
  .t-lh15-52{
    line-height: 15.52px;
  }
  .t-lh15-37{
    line-height: 15.37px;
  }
  .t-lh15-07{
    line-height: 15.07px;
  }
  .t-lh15-4{
    line-height: 15.4px;
  }
  .t-lh15-1{
    line-height: 15.1px;
  }
  .t-lh15{
    line-height: 15px;
  }
  .t-lh14-98{
    line-height: 14.98px;
  }
  .t-lh14-67{
    line-height: 14.67px;
  }
  .t-lh14-66{
    line-height: 14.66px;
  }
  .t-lh14-56{
    line-height: 14.56px;
  }
  .t-lh14{
    line-height: 14px;
  }
  .t-lh13-62{
    line-height: 13.62px;
  }
  .t-lh13{
    line-height: 13px;
  }
  .t-lh13-15{
    line-height: 13.15px;
  }
  .t-lh12-91{
    line-height: 12.91px;
  }
  .t-lh12-66{
    line-height: 12.66px;
  }
  .t-lh12{
    line-height: 12px;
  }
  .t-lh10-89{
    line-height: 10.89px;
  }
  .t-lh10-87{
    line-height: 10.87px;
  }
  .t-lh10{
    line-height: 10px;
  }
  .t-lh11-94{
    line-height: 11.94px;
  }
  .t-lh11-41{
    line-height: 11.41px;
  }
  .t-lh11-15{
    line-height: 11.15px;
  }
  .t-lh11{
    line-height: 11px;
  }
  .t-lh9-53{
    line-height: 9.53px;
  }
  
  
  .h100{
    height: 100%;
  }
  .tr{
    text-align: right;
  }
  
  
  
  /* Font Weight */
  .t-fwb{
    font-weight: bold;
  }
  .t-fw700{
    font-weight: 700 !important;
  }
  .t-fw600{
    font-weight: 600;
  }
  .t-fw500{
    font-weight: 500;
  }
  .t-fw400{
    font-weight: 400;
  }
  .t-fw300{
    font-weight: 300;
  }

  .ml-15{
    margin-left: 15px !important;
  }

  .mr-10{
    margin-right: 10px !important;
  }