/* Fonts */

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    src: url('Manrope.ttf');
}

@font-face {
    font-family: 'Jakarta Sans';
    font-style: normal;
    src: url('../../assets/fonts/PlusJakartaSans-Regular.ttf');
}